/**
 * Copied from $MONOREPO/calculator/btl-calculator
 */
import RCSlider, { SliderProps } from "rc-slider"
import styled from "@emotion/styled"
import { breakpoints, colours, mixins } from "design-kit"

const borderRadiusBase = "6px"

const Container = styled.div`
  width: 100%;

  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: ${borderRadiusBase};
    margin-top: 20px;
    touch-action: none;

    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-handle {
      position: absolute;
      cursor: pointer;
      cursor: -webkit-grab;
      cursor: grab;
      border-radius: 50%;
      touch-action: pan-x;
      background: ${colours.action.main};
      border: 0;
      border-radius: 100%;
      width: 24px;
      height: 24px;
      margin-top: -10px;
      user-select: none;

      ${breakpoints.tablet`
         right: 64px;
       `}

      &:focus {
        ${mixins.focused}
      }

      &-click-focused:focus {
        box-shadow: none;
      }
    }

    .rc-slider-rail {
      position: absolute;
      width: 100%;
      border-radius: ${borderRadiusBase};
      background-color: ${colours.greyScale.grey50};
      height: 6px;
    }

    .rc-slider-track {
      position: absolute;
      left: 0;
      border-radius: ${borderRadiusBase};
      background-color: ${colours.action.main};
      height: 6px;
    }

    &-handle {
      &:hover {
        border-color: tint(${colours.action.main}, 20%);
      }

      &:active {
        border-color: tint(${colours.action.main}, 20%);
        box-shadow: 0 0 5px tint(${colours.action.main}, 20%);
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }

    &-mark {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      font-size: 12px;
    }

    &-mark-text {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: ${colours.greyScale.grey75};

      &-active {
        color: ${colours.greyScale.grey100};
      }
    }

    &-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
    }

    &-dot {
      position: absolute;
      bottom: -2px;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border: 2px solid ${colours.greyScale.grey25};
      background-color: ${colours.white};
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
      &-active {
        border-color: tint(${colours.action.main}, 50%);
      }
    }

    &-disabled {
      background-color: ${colours.greyScale.grey25};

      .rc-slider-track {
        background-color: ${colours.greyScale.grey25};
      }

      .rc-slider-handle,
      .rc-slider-dot {
        border-color: ${colours.greyScale.grey25};
        box-shadow: none;
        background-color: ${colours.white};
        cursor: not-allowed;
      }

      .rc-slider-mark-text,
      .rc-slider-dot {
        cursor: not-allowed !important;
      }
    }
  }

  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
    100% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
  }

  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
  }
`

export const Slider: React.FunctionComponent<SliderProps<number>> = props => (
  <Container>
    <RCSlider
      {
        // rc-slider types are not great
        // RCSlider requires SliderProps<number | number[]>
        // to support both single and range sliders
        // we only care about single sliders
        ...(props as SliderProps)
      }
    />
  </Container>
)
